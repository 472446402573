import React from 'react'
import './experiences-style.css'
import OrganizationExperiences from './OrganizationExperiences'
import OtherExperiences from './OtherExperiences'
import ProjectExperiences from './ProjectExperiences'
import WorkExperiences from './WorkExperiences'

export default function Experiences() {
    return (
        <div id="experiences">
            <div className='container'>
                <div className='row pt-3 area-experiences pb-5'>
                    <div className='col-lg-12'>
                        <h4>Experiences</h4>
                    </div>
                    <div className='col-lg-12'>
                        <div className='row g-3'>
                            
                            <WorkExperiences />

                            <div className='col-lg-12 mt-5'>
                            <hr />
                            </div>
                            <ProjectExperiences />

                            <OrganizationExperiences />

                            <OtherExperiences />

                            

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
