import React from 'react'
import './aboutus-style.css'
import LogoPolban from './../../../assets/image/logo-polban-80.png'

export default function AboutMe() {
    return (
        <div id="about">
            <div className='container'>
                <div className='row pt-3 area-about-us pb-3'>
                    <div className='col-lg-12'>
                        <h4>About</h4>
                    </div>
                    <div className='col-lg-12'>
                        <div className='row'>
                            <div className='col-lg-7 col-md-6'>
                                <h5>Description</h5>
                                <p>Experienced software engineer for 3+ years. Currently working as Software Engineer with specialization as a <b>Frontend Developer</b> with framework <b>React JS</b> and as a <b>Backend Developer</b> using <b>Express JS</b>. Additionally experienced as Frontend developer, Also have strong <b>Analytical Skill</b>, <b>Fast Learner</b> and <b>Leadership</b>.</p>
                            </div>
                            <div className='col-lg-5 col-md-6'>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <h5>Academic Background</h5>
                                    </div>
                                    <div className='col-lg-12'>
                                        <div className='row'>
                                            <div className='col-lg-3'>
                                                <div className='area-logo-academic'>
                                                    <img src={LogoPolban} alt="logo-polban" className='logo-academic'/>
                                                </div>
                                            </div>
                                            <div className='col-lg-9'>
                                                <i style={{fontSize:"10pt"}}>2015-2019</i>
                                                <p className='academic-item-1'>
                                                    <b>Bandung State of Polytechnic (POLBAN)</b>
                                                    <br />
                                                    <span><i>Bachelor of Applied Science - Informatics Engineering</i></span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
