import React from 'react'
import LogoBinar from './../../../assets/image/logo-binar.png'
import LogoSkivul from './../../../assets/image/skilvul.svg'
import LogoMarkoding from './../../../assets/image/logo-markoding.png'
import LogoDts from './../../../assets/image/logo-dts.png'

export default function OtherExperiences() {
    return (
        <>
            <div className='col-lg-12'>
                <h5 className=' mt-3'>Other Experience</h5>
            </div>

            <div className='col-lg-4 col-md-6'>
                <div className='card card-glassmorp'>
                    <div className='row area-card-exp'>
                        <div className='col-lg-3'>
                            <div className='area-logo-experiences'>
                                <img src={LogoBinar} alt="logo-polban" className='logo-experiences'/>
                            </div>
                        </div>
                        
                        <div className='col-lg-9'>
                            <i style={{fontSize:"10pt"}}>February - Present</i>
                            <p className='experiences-item-1'>
                                <b>Fasilitator Full Stack Dev.</b>
                                <br />
                                <span><i>Binar Academy Batch 2 </i></span>
                            </p>
                        </div>
                        <div className='offset-lg-3 col-lg-9 description-experience'>
                            <span>Delivering material on Basic Web Development, Including React JS and Express JS. Teaching Skill Development</span>
                        </div>
                        
                    </div>
                </div>
            </div>

            <div className='col-lg-4 col-md-6'>
                <div className='card card-glassmorp'>
                    <div className='row area-card-exp'>
                        <div className='col-lg-3'>
                            <div className='area-logo-experiences'>
                                <img src={LogoSkivul} alt="logo-polban" className='logo-experiences'/>
                            </div>
                        </div>
                        
                        <div className='col-lg-9'>
                            <i style={{fontSize:"10pt"}}>Sept - Dec 2021</i>
                            <p className='experiences-item-1'>
                                <b>Mentor Full Stack Developer</b>
                                <br />
                                <span><i>#Tech4Impact - Skilvul</i></span>
                            </p>
                        </div>
                        <div className='offset-lg-3 col-lg-9 description-experience'>
                            <span>Delivering material on Basic Web Development, Including React JS and Express JS.</span>
                        </div>
                        
                    </div>
                </div>
            </div>

            <div className='col-lg-4 col-md-6'>
                <div className='card card-glassmorp'>
                    <div className='row area-card-exp'>
                        <div className='col-lg-3'>
                            <div className='area-logo-experiences'>
                                <img src={LogoMarkoding} alt="logo-polban" className='logo-experiences'/>
                            </div>
                        </div>
                        
                        <div className='col-lg-9'>
                            <i style={{fontSize:"10pt"}}>Jul - Sept 2021</i>
                            <p className='experiences-item-1'>
                                <b>Mentor Assist. Web dev</b>
                                <br />
                                <span><i>Digital Innovation Challenge - Markoding</i></span>
                            </p>
                        </div>
                        <div className='offset-lg-3 col-lg-9 description-experience'>
                            <span>Support Lead Mentor in web development and lead team to develop final project.</span>
                        </div>
                        
                    </div>
                </div>
            </div>

            <div className='col-lg-4 col-md-6'>
                <div className='card card-glassmorp'>
                    <div className='row area-card-exp'>
                        <div className='col-lg-3'>
                            <div className='area-logo-experiences'>
                                <img src={LogoDts} alt="logo-polban" className='logo-experiences'/>
                            </div>
                        </div>
                        <div className='col-lg-9'>
                            <i style={{fontSize:"10pt"}}>Jul - Aug 2019</i>
                            <p className='experiences-item-1'>
                                <b>Mentor Intermediate Animator</b>
                                <br />
                                <span><i>DTS - Kominfo</i></span>
                            </p>
                        </div>
                        <div className='offset-lg-3 col-lg-9 description-experience'>
                            <span>Delivering learning material about make animation using After Effect and Blender.</span>
                        </div>
                        
                    </div>
                </div>
            </div>

            

            
        </>
    )
}
