import React, {useEffect} from 'react'
import './assets/app-style.css'
import Header from './components/_header/Header'
import AboutMe from './components/_page/about-me/AboutMe';
import Experiences from './components/_page/experiences/Experiences';
import Home from './components/_page/home/Home'
import Skills from './components/_page/skills/Skills';
import Portofolios from './components/_page/portofolios/Portofolios';
import Achievements from './components/_page/achievements/Achievements';
import Footer from './components/_footer/Footer';

function App() {

  // useGaTracker();

  return (
    <div className="App">

      {/* <Home /> */}

      <Header />

      <Home />

      <AboutMe />

      <Experiences />

      <Skills />

      <Portofolios />

      <Achievements />

      <Footer />
      
    </div>
  );
}

export default App;
