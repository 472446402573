import React from 'react'
import LogoKbb from './../../../assets/image/logo-kbb.png'
import LogoPussimpur from './../../../assets/image/Logo_kodiklat.png'

export default function ProjectExperiences() {
    return (
        <>
            <div className='col-lg-12'>
                <h5 className=' mt-3'>Project Experience</h5>
            </div>

            <div className='col-lg-4 col-md-6'>
                <div className='card card-glassmorp'>
                    <div className='row area-card-exp'>
                        <div className='col-lg-3'>
                            <div className='area-logo-experiences'>
                                <img src={LogoKbb} alt="logo-polban" className='logo-experiences'/>
                            </div>
                        </div>
                        <div className='col-lg-9'>
                            <i style={{fontSize:"10pt"}}>Aug - Nov 2021</i>
                            <p className='experiences-item-1'>
                                <b>Sistem Informasi Perizinan </b>
                                <br />
                                <span><i>Dinas Kesehatan Kab. Bandung Barat</i></span>
                            </p>
                        </div>
                        {/* <div className='offset-lg-3 col-lg-9 description-experience'>
                            <span>Developed Maritime Information System which is data processed, analyzed, and rendered data visually.</span>
                        </div> */}
                        <div className='offset-lg-3 col-lg-9 mt-1'>
                            <span className="badge rounded-pill bg-dark-custom ">Laravel</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className='col-lg-4 col-md-6'>
                <div className='card card-glassmorp'>
                    <div className='row area-card-exp'>
                        <div className='col-lg-3'>
                            <div className='area-logo-experiences'>
                                <img src={LogoPussimpur} alt="logo-polban" className='logo-experiences'/>
                            </div>
                        </div>
                        <div className='col-lg-9'>
                            <i style={{fontSize:"10pt"}}>Jan - May 2019</i>
                            <p className='experiences-item-1'>
                                <b>Simulator Combat System</b>
                                <br />
                                <span><i>PUSSIMPUR TNI AD</i></span>
                            </p>
                        </div>
                        {/* <div className='offset-lg-3 col-lg-9 description-experience'>
                            <span>Developed backend for system One Gate Dashboard Telkom DWS which is data processing.</span>
                        </div> */}
                        <div className='offset-lg-3 col-lg-9 mt-1'>
                            <span className="badge rounded-pill bg-dark-custom">Unreal Engine</span>
                        </div>
                    </div>
                </div>
            </div>

            
        </>
    )
}
