import React from 'react';
import './footer-style.css'

export default function Footer() {
  return (
    <div className='area-footer'>
        <div className='container pt-5 pb-5'>
            <div className='col-lg-12'>
                <a href="https://www.digitalocean.com/?refcode=d28a196a9b06&utm_campaign=Referral_Invite&utm_medium=Referral_Program&utm_source=badge"><img src="https://web-platforms.sfo2.digitaloceanspaces.com/WWW/Badge%202.svg" alt="DigitalOcean Referral Badge" /></a>
                <hr style={{borderBottom:"0.5px solid #fff"}}/>
                <small style={{color:"white"}}>Last Updated : January, 26 2022</small>
                <br />
                <small style={{color:"white"}}>2022 &copy; made with love - by Reka Alamsyah</small>
            </div>
        </div>
    </div>
  );
}
