import React from 'react'
import LogoHimakom from './../../../assets/image/logo-himakom.png'

export default function OrganizationExperiences() {
    return (
        <>
            <div className='col-lg-12'>
                <h5 className=' mt-3'>Organization Experience</h5>
            </div>

            <div className='col-lg-4 col-md-6'>
                <div className='card card-glassmorp'>
                    <div className='row area-card-exp'>
                        <div className='col-lg-3'>
                            <div className='area-logo-experiences'>
                                <img src={LogoHimakom} alt="logo-polban" className='logo-experiences'/>
                            </div>
                        </div>
                        <div className='col-lg-9'>
                            <i style={{fontSize:"10pt"}}>2019 - Present</i>
                            <p className='experiences-item-1'>
                                <b>Chairman</b>
                                <br />
                                <span><i>HIMAKOM</i></span>
                            </p>
                        </div>
                        <div className='offset-lg-3 col-lg-9 description-experience'>
                            <span>Lead organization Himpunan Mahasiswa Komputer (HIMAKOM) - Bandung State of Polytechnic</span>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
