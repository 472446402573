import React from 'react'
import LogoLen from './../../../assets/image/Logo_Len_Industri.png'
import LogoSirkel from './../../../assets/image/logo-sirkel.svg'
import LogoGt from './../../../assets/image/logo-gt.png'
import Slider from "react-slick";
import { settings_3 } from './slick-const';

export default function WorkExperiences() {
    
    return (
        <>
            <div className='col-lg-12'>
                <h5>Work Experience</h5>
            </div>
            
            <Slider {...settings_3}>
                <div className='col-lg-4 col-md-6 gap-1 align-items-stretch d-flex '>
                    <div className='card card-glassmorp'>
                        <div className='row area-card-exp'>
                            <div className='col-lg-3'>
                                <div className='area-logo-experiences'>
                                    <img src={LogoLen} alt="logo-polban" className='logo-experiences'/>
                                </div>
                            </div>
                            <div className='col-lg-9'>
                                <i style={{fontSize:"10pt"}}>2019 - Present (Full Time)</i>
                                <p className='experiences-item-1'>
                                    <b>Fullstack Developer</b>
                                    <br />
                                    <span><i>PT. Len Industri (Persero)</i></span>
                                </p>
                            </div>
                            <div className='offset-lg-3 col-lg-9 description-experience'>
                                <span>Developed Maritime Information System which is data processed, analyzed, and rendered data visually.</span>
                            </div>
                            <div className='offset-lg-3 col-lg-9 stack-'>
                                <span className="badge rounded-pill bg-dark-custom ">React JS</span>
                                &nbsp;
                                <span className="badge rounded-pill bg-dark-custom ">Node JS</span>
                                &nbsp;
                                <span className="badge rounded-pill bg-dark-custom ">Docker</span>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className='col-lg-4 col-md-6 gap-1 align-items-stretch d-flex '>
                    <div className='card card-glassmorp'>
                        <div className='row area-card-exp'>
                            <div className='col-lg-3'>
                                <div className='area-logo-experiences'>
                                    <img src={LogoSirkel} alt="logo-polban" className='logo-experiences'/>
                                </div>
                            </div>
                            <div className='col-lg-9'>
                                <i style={{fontSize:"10pt"}}>May - Oct 2021 (Part Time)</i>
                                <p className='experiences-item-1'>
                                    <b>Backend Developer</b>
                                    <br />
                                    <span><i>PT. Sirkel Kreasi Nusantara</i></span>
                                </p>
                            </div>
                            <div className='offset-lg-3 col-lg-9 description-experience'>
                                <span>Developed backend for system One Gate Dashboard Telkom DWS which is data processing.</span>
                            </div>
                            <div className='offset-lg-3 col-lg-9 stack-'>
                                <span className="badge rounded-pill bg-dark-custom ">Express JS</span>
                                &nbsp;
                                <span className="badge rounded-pill bg-dark-custom ">Docker</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-lg-4 col-md-6 gap-1 align-items-stretch d-flex '>
                    <div className='card card-glassmorp'>
                        <div className='row area-card-exp'>
                            <div className='col-lg-3'>
                                <div className='area-logo-experiences'>
                                    <img src={LogoSirkel} alt="logo-polban" className='logo-experiences'/>
                                </div>
                            </div>
                            <div className='col-lg-9'>
                                <i style={{fontSize:"10pt"}}>Dec 2020 - Jan 2021 (Part Time)</i>
                                <p className='experiences-item-1'>
                                    <b>Fullstack Developer</b>
                                    <br />
                                    <span><i>PT. Sirkel Kreasi Nusantara</i></span>
                                </p>
                            </div>
                            <div className='offset-lg-3 col-lg-9 description-experience'>
                                <span>Developed Application Zakat, Infaq, & Wakaf (Ziswaf) Bank Syariah Indonesia</span>
                            </div>
                            <div className='offset-lg-3 col-lg-9 stack-'>
                                <span className="badge rounded-pill bg-dark-custom ">Laravel</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-lg-4 col-md-6 gap-1 align-items-stretch d-flex '>
                    <div className='card card-glassmorp'>
                        <div className='row area-card-exp'>
                            <div className='col-lg-3'>
                                <div className='area-logo-experiences'>
                                    <img src={LogoGt} alt="logo-polban" className='logo-experiences'/>
                                </div>
                            </div>
                            <div className='col-lg-9'>
                                <i style={{fontSize:"10pt"}}>Sept 2019 - Jan 2020 (Part Time)</i>
                                <p className='experiences-item-1'>
                                    <b>Fullstack Developer</b>
                                    <br />
                                    <span><i>PT. Gamatechno Indonesia</i></span>
                                </p>
                            </div>
                            <div className='offset-lg-3 col-lg-9 description-experience'>
                                <span>Developed Application Panic Button Daerah Istimewa Yogyakarta (DIY).</span>
                            </div>
                            <div className='offset-lg-3 col-lg-9 stack-'>
                                <span className="badge rounded-pill bg-dark-custom ">Laravel</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-lg-4 col-md-6 gap-1 align-items-stretch d-flex '>
                    <div className='card card-glassmorp'>
                        <div className='row area-card-exp'>
                            <div className='col-lg-3'>
                                <div className='area-logo-experiences'>
                                    <img src={LogoGt} alt="logo-polban" className='logo-experiences'/>
                                </div>
                            </div>
                            <div className='col-lg-9'>
                                <i style={{fontSize:"10pt"}}>July - Nov 2018 (Part Time)</i>
                                <p className='experiences-item-1'>
                                    <b>Frontend Developer</b>
                                    <br />
                                    <span><i>PT. Gamatechno Indonesia</i></span>
                                </p>
                            </div>
                            <div className='offset-lg-3 col-lg-9 description-experience'>
                                <span>Developed Feature Virtual Tour 360 mCity Kudus city and Yogyakarta</span>
                            </div>
                            <div className='offset-lg-3 col-lg-9 stack-'>
                                <span className="badge rounded-pill bg-dark-custom ">React JS</span>
                                &nbsp;
                                <span className="badge rounded-pill bg-dark-custom ">Lumen Laravel</span>
                            </div>
                        </div>
                    </div>
                </div>
            </Slider>            
        </>
    )
}
