import React from 'react'
import PhotoProfile from './../../../assets/image/photo-profile.png'
import { FaFacebookSquare } from "react-icons/fa"
import { AiFillInstagram } from "react-icons/ai"
import { BsGithub, BsLinkedin } from "react-icons/bs"
import './home-style.css'

export default function Home() {
    return (
        <div id="home">
            <div className='area-home'>
                {/* Content */}
                <div className='container-fluid' style={{alignSelf:"center"}}>
                    <div className='content-area'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-4 offset-md-4 col-md-4 offset-sm-4 col-sm-2 offset-2 col-8 d-lg-none' style={{display:"flex"}}>
                                    <div className='image-jumbotron-area'>
                                        <img src={PhotoProfile} alt="profile-3" className='img-fluid'/>
                                    </div>
                                </div>
                                <div className='col-lg-8 col-md-12 col-sm-12 col-12'>
                                    <div className='text-jumbotron-area'>
                                        <div className='content-jumbotron'>
                                            <h1>I’m Reka Alamsyah</h1>
                                            <p>Full stack developer | Frontend Developer | Backend Developer</p>
                                            {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias officiis dicta omnis nihil quia molestiae voluptates tempore minima amet aliquid excepturi assumenda nam adipisci, a nulla rerum minus debitis enim.</p> */}
                                            <div className='social-media-area'>
                                                {/* <a href={`https://www.facebook.com/rekaalamsyah31/`} target={`_blank`} className='btn btn-classic'><FaFacebookSquare color="white" size={21}/> &nbsp;Facebook</a> */}
                                                <a href={`https://www.instagram.com/rekaa_as/`} target={`_blank`} className='btn btn-classic'><AiFillInstagram color="white" size={21}/> &nbsp;Instagram</a>
                                                <a href={`https://www.linkedin.com/in/reka-alamsyah/`} target={`_blank`} className='btn btn-classic'><BsLinkedin color="white" size={21}/> &nbsp;LinkedIn</a>
                                                <a href={`https://github.com/rekaa31`} target={`_blank`} className='btn btn-classic'><BsGithub color="white" size={21}/> &nbsp;Github</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-4 col-12 d-none d-lg-block' style={{display:"flex"}}>
                                    <div className='image-jumbotron-area'>
                                        <img src={PhotoProfile} alt="profile-2" className='img-fluid'/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
