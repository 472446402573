import React from 'react'
import './skill-style.css'
import ReactApexChart  from "react-apexcharts";
import ProgrammingSkillData from './data/programming-skill.json'

export default function Skills() {

    let data_skill_summary = {    
        "series": [{
            "data": [95, 82, 60, 40, 80]
        }],
        
        "options": {
            "labels" : ["Frontend", "Backend", "Data Science", "DevOps", "UI Design"],
            "chart": {
                "type": "radar",
                "height" : "100%",
                "toolbar" : false,
                "zoom": {
                    "enabled": false
                },
            },
            dataLabels: {
                enabled: true
            },
            plotOptions: {
                radar: {
                size: 140,
                    polygons: {
                        strokeColors: '#e9e9e9',
                        fill: {
                        colors: ['#f8f8f8', '#fff']
                        }
                    }
                }
            },
            colors: ['rgba(202, 55, 10, 0.6)'],
            markers: {
                size: 4,
                colors: ['rgb(202, 55, 10)'],
                strokeColor: 'rgb(202, 55, 10)',
                strokeWidth: 2,
            },
            "tooltip" : {
                "y": {
                    "formatter": undefined,
                    "title": {
                        formatter: () => "Value :",
                    },
                },
                custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                    return(
                        `
                            <div style="padding:10px">
                                <b>${w.globals.labels[dataPointIndex]}</b> <br /> ${series[seriesIndex][dataPointIndex] >= 90 ? "Expert" : (series[seriesIndex][dataPointIndex] < 90) && (series[seriesIndex][dataPointIndex] >= 75) ? "Specialist" : (series[seriesIndex][dataPointIndex] <= 50) ? "Amature" : "" }
                            </div>
                        `
                    )
                }
            },
            
        }
    };

    return (
        <div id="skills">
            <div className='container'>
                <div className='row pt-3 area-skills pb-5'>
                    <div className='col-lg-12'>
                        <h4 className='mt-3 mb-4'>Skills</h4>
                    </div>
                    <hr style={{borderBottom:"0.5px solid #fff"}}/>
                    <div className='col-lg-12'>
                        <div className='row mt-3 g-3'>
                            {/* <div className='col-lg-12'>
                                <h5>Hard Skill</h5>
                            </div> */}

                            
                            <div className='offset-lg-3 col-lg-6 d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block'>
                                <ReactApexChart 
                                    options={data_skill_summary.options}
                                    series={data_skill_summary.series}
                                    type="radar"
                                    height={"340px"}
                                />
                            </div>

                            <div className='col-lg-6 col-md-12 col-12'>
                                <div className="accordion" id="accordionSideOne">
                                    <div className="accordion-item accordion-item-dark">
                                        <h2 className="accordion-header" id="headingFe">
                                            <button className="accordion-button accordion-button-dark " type="button" data-bs-toggle="collapse" data-bs-target="#collapseFe" aria-expanded="true" aria-controls="collapseFe">
                                                Frontend
                                            </button>
                                        </h2>
                                        <div id="collapseFe" className="accordion-collapse collapse show" aria-labelledby="headingFe" data-bs-parent="#accordionSideOne">
                                            <div className="accordion-body accordion-body-dark">
                                                <h6>Programming Language</h6>
                                                <p className='mb-3'>Javascript ES6 & Vanilla JS : Expert</p>
                                                <div className='row'>
                                                    <div className='col-lg-6'>
                                                        <h6>Framework</h6>
                                                        <table style={{width:"100%"}}>
                                                            <tbody>
                                                                <tr>
                                                                    <td width={20}>1.</td>
                                                                    <td width={100}>React JS</td>
                                                                    <td width={10}>:</td>
                                                                    <td>Expert</td>
                                                                </tr>
                                                                <tr>
                                                                    <td width={20}>2.</td>
                                                                    <td width={100}>JQuery</td>
                                                                    <td width={10}>:</td>
                                                                    <td>Expert</td>
                                                                </tr>
                                                                <tr>
                                                                    <td width={20}>3.</td>
                                                                    <td width={100}>Bootstrap</td>
                                                                    <td width={10}>:</td>
                                                                    <td>Expert</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <h6>Library</h6>
                                                        <table style={{width:"100%"}}>
                                                            <tbody>
                                                                <tr>
                                                                    <td width={20}>1.</td>
                                                                    <td width={100}>Leaflet JS</td>
                                                                    <td width={10}>:</td>
                                                                    <td>Expert</td>
                                                                </tr>
                                                                <tr>
                                                                    <td width={20}>2.</td>
                                                                    <td width={100}>Apexchart</td>
                                                                    <td width={10}>:</td>
                                                                    <td>Specialist</td>
                                                                </tr>
                                                                <tr>
                                                                    <td width={20}>3.</td>
                                                                    <td width={100}>Slick</td>
                                                                    <td width={10}>:</td>
                                                                    <td>Expert</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item accordion-item-dark">
                                        <h2 className="accordion-header" id="headingBe">
                                            <button className="accordion-button accordion-button-dark collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseBe" aria-expanded="false" aria-controls="collapseBe">
                                                Backend
                                            </button>
                                        </h2>
                                        <div id="collapseBe" className="accordion-collapse collapse" aria-labelledby="headingBe" data-bs-parent="#accordionSideOne">
                                            <div className="accordion-body accordion-body-dark">
                                                
                                                <div className='row'>
                                                    <div className='col-lg-6'>
                                                        <h6>Programming Language</h6>
                                                        <table style={{width:"100%"}}>
                                                            <tbody>
                                                                <tr>
                                                                    <td width={20}>1.</td>
                                                                    <td width={100}>Javascript</td>
                                                                    <td width={10}>:</td>
                                                                    <td>Expert</td>
                                                                </tr>
                                                                <tr>
                                                                    <td width={20}>2.</td>
                                                                    <td width={100}>PHP</td>
                                                                    <td width={10}>:</td>
                                                                    <td>Expert</td>
                                                                </tr>
                                                                <tr>
                                                                    <td width={20}>3.</td>
                                                                    <td width={100}>Python</td>
                                                                    <td width={10}>:</td>
                                                                    <td>Specialist</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <h6>Framework</h6>
                                                        <table style={{width:"100%"}}>
                                                            <tbody>
                                                                <tr>
                                                                    <td width={20}>1.</td>
                                                                    <td width={100}>Express JS</td>
                                                                    <td width={10}>:</td>
                                                                    <td>Expert</td>
                                                                </tr>
                                                                <tr>
                                                                    <td width={20}>2.</td>
                                                                    <td width={150}>Laravel / Lumen</td>
                                                                    <td width={10}>:</td>
                                                                    <td>Expert</td>
                                                                </tr>
                                                                <tr>
                                                                    <td width={20}>3.</td>
                                                                    <td width={100}>Flask</td>
                                                                    <td width={10}>:</td>
                                                                    <td>Specialist</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item accordion-item-dark">
                                        <h2 className="accordion-header" id="headingDs">
                                            <button className="accordion-button accordion-button-dark collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseDs" aria-expanded="false" aria-controls="collapseDs">
                                                Data Science
                                            </button>
                                        </h2>
                                        <div id="collapseDs" className="accordion-collapse collapse" aria-labelledby="headingDs" data-bs-parent="#accordionSideOne">
                                            <div className="accordion-body accordion-body-dark">
                                                <h6>Programming Language</h6>
                                                <p className='mb-3'>Python : Specialist</p>

                                                <h6>Libary</h6>
                                                <p className='mb-0'>SpaCy : Amature</p>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>              
                            </div>
                            
                            <div className='col-lg-6 col-md-12 col-12'>
                                <div className="accordion" id="accordionSideTwo">
                                    
                                    <div className="accordion-item accordion-item-dark">
                                        <h2 className="accordion-header" id="headingUi">
                                            <button className="accordion-button accordion-button-dark collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseUi" aria-expanded="false" aria-controls="collapseUi">
                                                UI Design
                                            </button>
                                        </h2>
                                        <div id="collapseUi" className="accordion-collapse collapse" aria-labelledby="headingUi" data-bs-parent="#accordionSideTwo">
                                            <div className="accordion-body accordion-body-dark">
                                                <h6>Tools</h6>
                                                <table style={{width:"100%"}}>
                                                    <tbody>
                                                        <tr>
                                                            <td width={20}>1.</td>
                                                            <td width={100}>Figma</td>
                                                            <td width={10}>:</td>
                                                            <td>Specialist</td>
                                                        </tr>
                                                        <tr>
                                                            <td width={20}>2.</td>
                                                            <td width={150}>Adobe XD</td>
                                                            <td width={10}>:</td>
                                                            <td>Specialist</td>
                                                        </tr>
                                                        <tr>
                                                            <td width={20}>3.</td>
                                                            <td width={100}>Adobe Illustrator</td>
                                                            <td width={10}>:</td>
                                                            <td>Specialist</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item accordion-item-dark">
                                        <h2 className="accordion-header" id="headingDevOps">
                                            <button className="accordion-button accordion-button-dark collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseDevOps" aria-expanded="false" aria-controls="collapseDevOps">
                                                DevOps
                                            </button>
                                        </h2>
                                        <div id="collapseDevOps" className="accordion-collapse collapse" aria-labelledby="headingDevOps" data-bs-parent="#accordionSideTwo">
                                            <div className="accordion-body accordion-body-dark">
                                                <h6>Tools</h6>
                                                <table style={{width:"100%"}}>
                                                    <tbody>
                                                        <tr>
                                                            <td width={20}>1.</td>
                                                            <td width={100}>Docker</td>
                                                            <td width={10}>:</td>
                                                            <td>Amature</td>
                                                        </tr>
                                                        <tr>
                                                            <td width={20}>2.</td>
                                                            <td width={150}>Kubernetes</td>
                                                            <td width={10}>:</td>
                                                            <td>Amature</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div> 
                            </div>

                            {/* <div className='col-lg-12'>
                                <h5>Soft Skill</h5>
                            </div>

                            <div className='col-lg-12'>
                                <div className='row'>
                                    <div className='col-lg-4 col-md-8 col-12'>
                                        <ReactApexChart 
                                            options={data_skill_summary.options}
                                            series={data_skill_summary.series}
                                            type="radar"
                                        />
                                    </div>
                                    <div className='col-lg-4 col-md-8 col-12'>
                                        <ReactApexChart 
                                            options={data_skill_summary.options}
                                            series={data_skill_summary.series}
                                            type="radar"
                                        />
                                    </div>
                                    <div className='col-lg-4 col-md-8 col-12'>
                                        <ReactApexChart 
                                            options={data_skill_summary.options}
                                            series={data_skill_summary.series}
                                            type="radar"
                                        />
                                    </div>
                                </div>
                            </div>
                             */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
