import React from 'react'
import './portofolios-style.css'

export default function Portofolios() {
    return (
        <div id='portofolios'>
            <div className='container'>
                <div className='row pt-3 area-portofolios pb-5'>
                    <div className='col-lg-12'>
                        <h4 className='mt-2 '>Portofolios</h4>
                        <h5 className='mb-4'>Project</h5>
                    </div>

                    <div className='col-lg-12'>
                        <div className='row g-3'>
                            <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
                                <div className='card card-glassmorp'>
                                    <div className='row area-card-porto'>
                                    {/* <img src="https://res.cloudinary.com/polbanjuara21/image/upload/v1642073785/reka-portofolios/yoberbagi-porto.png" alt="logo-polban" className='logo-experiences'/> */}
                                        <div class="hovereffect ps-0 pe-0">
                                            <img src="https://res.cloudinary.com/polbanjuara21/image/upload/v1642073785/reka-portofolios/yoberbagi-porto.png" className='img-fluid' alt="" />
                                            <a onClick={() => window.open("http://legacy.yoberbagi.id/", '_blank', 'noopener,noreferrer')} className="tile">
                                            <div className="overlay">
                                                <p>
                                                    <h5><b>Yoberbagi.id</b></h5>
                                                    <p> Yoberbagi is built to be a medium for sharing, knowledge, skill, tips, idea and positive inspiration from individuals or cominities for Indonesia in audio-visual media  </p>
                                                </p>
                                            </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
                                <div className='card card-glassmorp'>
                                    <div className='row area-card-porto '>
                                    {/* <img src="https://res.cloudinary.com/polbanjuara21/image/upload/v1642073785/reka-portofolios/yoberbagi-porto.png" alt="logo-polban" className='logo-experiences'/> */}
                                        <div class="hovereffect ps-0 pe-0">
                                            <img src="https://res.cloudinary.com/polbanjuara21/image/upload/v1642075567/reka-portofolios/sisangkuriang-porto.png" className='img-fluid' alt="" />
                                            <a onClick={() => window.open("http://sisangkuriang.web.id/login", '_blank', 'noopener,noreferrer')} className="tile">
                                            <div className="overlay">
                                                <p>
                                                    <h5><b>Sisangkuriang</b></h5>
                                                    <p> Sisangkuriang is data collection system for submitting an application for food distribution permit at Dinas Kesehatan Kabupaten Bandung Barat </p>
                                                </p>
                                            </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                </div>
            </div>
        </div>
    )
}
