import React from 'react'
import './achievements-style.css'
import Slider from "react-slick";
import Prestasi_One from './../../../assets/image/p1.jpg'
import Prestasi_Two from './../../../assets/image/p2.jpg'
import Prestasi_Three from './../../../assets/image/p3.jpg'
import Prestasi_Four from './../../../assets/image/p4.jpg'

export default function Achievements() {

    const settings_1 = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false
                }
            },
                {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: false
                }
            },
                {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false
                }
            },
                {
                breakpoint: 320,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false
                }
            }
        ]
    };
    return (
        <div id='achievements'>
            <div className='container'>
                <div className='row area-achievements'>
                    <div className='col-lg-12'>
                        <h4 className='mt-2 mb-3'>Achievements</h4>
                    </div>
                
                    <div className='col-lg-12'>
                        <Slider {...settings_1}>
                            <div className='col-lg-4 col-md-6 gap-1 align-items-stretch d-flex '>
                                <div className='card card-glassmorp'>
                                    <img src={Prestasi_One} alt="Prestasi Prestasi_One" />
                                    <p className='mt-4 mb-0 text-center'>2015 - Juara 3 Viral Video Competition</p>
                                </div>
                            </div>
                            
                            <div className='col-lg-4 col-md-6 gap-1 align-items-stretch d-flex '>
                                <div className='card card-glassmorp'>
                                    <img src={Prestasi_Two} alt="Prestasi Prestasi_Two" />
                                    <p className='mt-4 mb-0 text-center'>2016 - Juara 2 Web Design Competition Semarang</p>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-6 gap-1 align-items-stretch d-flex '>
                                <div className='card card-glassmorp'>
                                    <img src={Prestasi_Three} alt="Prestasi Prestasi_Three" />
                                    <p className='mt-4 mb-0 text-center'>2017 - Gold Medal International Art Creativity Engineering Exhibition Aceh</p>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-6 gap-1 align-items-stretch d-flex '>
                                <div className='card card-glassmorp'>
                                    <img src={Prestasi_Four} alt="Prestasi Prestasi_Four" />
                                    <p className='mt-4 mb-0 text-center'>2018 - Juara 1 Mobile Apps Competition</p>
                                </div>
                            </div>

                        </Slider>  
                    </div>
                </div>
            </div>
        </div>
    )
}
