// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&family=Roboto:wght@400;500;700;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "p{\n\tfont-family: 'Roboto', sans-serif;\n}\nh1, h2, h3, h4, h5, h6 {\n\tfont-family: 'Lora', serif;\n}\n\n.btn-classic{\n\tbackground-color: none;\n\tcolor: white !important;\n\tborder: rgba(255, 255, 255, 0.253) 1px solid !important;\n}\n\n.card-glassmorp{\n\t/* background: rgba(255, 255, 255, 0.555); */\n\tbackground-color: rgba(236, 236, 236, 0.589) !important;\n\tpadding: 25px;\n\theight: 100%;\n\tdisplay: flex;\n\talign-self: center;\n\tbox-shadow: 0 4px 5px rgba(61, 61, 61, 0.103);\n\tborder:rgba(126, 126, 126, 0.253) 0.5px solid !important;\n\t/* backdrop-filter: blur(3px);\n\t-webkit-backdrop-filter: blur(3px); */\n}", "",{"version":3,"sources":["webpack://./src/assets/app-style.css"],"names":[],"mappings":"AAGA;CACC,iCAAiC;AAClC;AACA;CACC,0BAA0B;AAC3B;;AAEA;CACC,sBAAsB;CACtB,uBAAuB;CACvB,uDAAuD;AACxD;;AAEA;CACC,4CAA4C;CAC5C,uDAAuD;CACvD,aAAa;CACb,YAAY;CACZ,aAAa;CACb,kBAAkB;CAClB,6CAA6C;CAC7C,wDAAwD;CACxD;sCACqC;AACtC","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&family=Roboto:wght@400;500;700;900&display=swap');\n\np{\n\tfont-family: 'Roboto', sans-serif;\n}\nh1, h2, h3, h4, h5, h6 {\n\tfont-family: 'Lora', serif;\n}\n\n.btn-classic{\n\tbackground-color: none;\n\tcolor: white !important;\n\tborder: rgba(255, 255, 255, 0.253) 1px solid !important;\n}\n\n.card-glassmorp{\n\t/* background: rgba(255, 255, 255, 0.555); */\n\tbackground-color: rgba(236, 236, 236, 0.589) !important;\n\tpadding: 25px;\n\theight: 100%;\n\tdisplay: flex;\n\talign-self: center;\n\tbox-shadow: 0 4px 5px rgba(61, 61, 61, 0.103);\n\tborder:rgba(126, 126, 126, 0.253) 0.5px solid !important;\n\t/* backdrop-filter: blur(3px);\n\t-webkit-backdrop-filter: blur(3px); */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
