import React from 'react'
import Logo from './../../assets/image/R.png'
import { GiHamburgerMenu } from "react-icons/gi"
import './header-style.css'
import { Link, animateScroll as scroll } from "react-scroll";
import ReactGA from 'react-ga';

export default function Header() {

    const handleSetActive = (data) => {
        ReactGA.initialize('UA-170124303-1');
        ReactGA.pageview(`/#${data}`);
    }

    return (
        <div className='menu-bar-area '>
            <div className='row'>

                {/* Logo */}
                <div className='col-md-10 col-sm-10 col-10 d-md-block d-lg-none'>
                    <img src={Logo} alt="reka-logo" />
                </div>

                {/* <div className='col-md-2 col-sm-2 col-2 d-md-block d-lg-none'>
                    <button className='btn btn-classic btn-menu-expand'><GiHamburgerMenu color="white" size={23}></GiHamburgerMenu></button>
                </div> */}
                
                {/* Menu */}
                <div className='col-lg-12 '>
                    <nav className="navbar navbar-expand-lg navbar-text-light navbar-custom d-none d-lg-block">
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <ul className="navbar-nav mt-2">
                            <li className="nav-item">
                            {/* <a className="nav-link nav-item-custom active" aria-current="page" href={"#"}>Home</a> */}
                                <Link
                                    activeClass="active"
                                    to="home"
                                    spy={true}
                                    smooth={true}
                                    hashSpy={true}
                                    offset={-70}
                                    duration={100}
                                    onSetActive={(data) => handleSetActive(data)}
                                    className='nav-link nav-item-custom'
                                >
                                    Home
                                </Link>
                            </li>
                            <li className="nav-item">
                            {/* <a className="nav-link nav-item-custom" href={"#section2"}>About</a> */}
                                <Link
                                    activeClass="active"
                                    to="about"
                                    spy={true}
                                    smooth={true}
                                    hashSpy={true}
                                    offset={-70}
                                    duration={100}
                                    onSetActive={(data) => handleSetActive(data)}
                                    className='nav-link nav-item-custom'
                                >
                                    About
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    activeClass="active"
                                    to="experiences"
                                    spy={true}
                                    smooth={true}
                                    hashSpy={true}
                                    offset={-70}
                                    duration={100}
                                    onSetActive={(data) => handleSetActive(data)}
                                    className='nav-link nav-item-custom'
                                >
                                    Experiences
                                </Link>
                            {/* <a className="nav-link nav-item-custom" href={"#"}>Experiences</a> */}
                            </li>
                            <li className="nav-item">
                                <Link
                                    activeClass="active"
                                    to="skills"
                                    spy={true}
                                    smooth={true}
                                    hashSpy={true}
                                    offset={-70}
                                    duration={100}
                                    onSetActive={(data) => handleSetActive(data)}
                                    className='nav-link nav-item-custom'
                                >
                                    Skills
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    activeClass="active"
                                    to="portofolios"
                                    spy={true}
                                    smooth={true}
                                    hashSpy={true}
                                    offset={-70}
                                    duration={100}
                                    onSetActive={(data) => handleSetActive(data)}
                                    className='nav-link nav-item-custom'
                                >
                                    Portofolios
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    activeClass="active"
                                    to="achievements"
                                    spy={true}
                                    smooth={true}
                                    hashSpy={true}
                                    offset={0}
                                    duration={100}
                                    onSetActive={(data) => handleSetActive(data)}
                                    className='nav-link nav-item-custom'
                                >
                                    Achievements
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>

                
            </div>
        </div>
    )
}
